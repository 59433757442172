import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./css/App.scss";
import request from "superagent";

import Consumer from "./Consumer";
import Homepage from "./Homepage";
import Logistics from "./Logistics";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
// This app sits in front of the old app, redirecting to a No ID component or the old App (now named ProcessPage), with the productItemId passed in
//
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetId: "",
      organization: "",
    };
  }

  componentDidMount() {
    //  Looks at the url for the query param 'assetId'
    let params = new URLSearchParams(document.location.search.substring(1));
    let assetId = params.get("assetId");

      this.setState({ assetId });
  }

  render() {
    const { assetId, organization } = this.state;
    const NoAuthRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          const token = process.env.REACT_APP_API_TOKEN
            
          return (
            <Component
              {...props}
              assetId={assetId}
              apiUrl={apiUrl}
              token={token}
              organization={this.state}
            />
          );
        }}
      />
    );
    return (
      <Router>
        <div>
          <Route
            path="/"
            exact
            render={(props) => (
              <Homepage {...props} organization={organization} />
            )}
          />
          <Route
            path="/home"
            render={(props) => (
              <Homepage {...props} organization={organization} />
            )}
          />
          {assetId !== null ? (
            <NoAuthRoute path="/Consumer*" component={Consumer} />
          ) : (
            <NoAuthRoute path="/Consumer/:assetId" component={Consumer} />
          )}
          <NoAuthRoute path="/Logistics/:assetId" component={Logistics} />
          <NoAuthRoute path="/Redirect" component={this.ProcessRedirect} />
        </div>
      </Router>
    );
  }
  NoId() {
    return (
      <div>
        <h3>Please provide a product item ID</h3>
      </div>
    );
  }

  ProcessRedirect(props) {
    let { search } = props.location;
    if (search.startsWith("?")) search = search.substring(1);
    const params = search.split("&");
    let redirect = null;
    params.forEach((p) => {
      const kv = p.split("=");
      if (kv[0] === "redirect") redirect = kv[1];
    });
    if (redirect) {
      window.location = redirect;
    }
    return <div>redirect not found</div>;
  }
}

import React from "react";
import Header from "./components/Header";
import ComponentPicker from "./utils/ComponentPicker";
import Footer from "./components/Footer";
import styled from "styled-components";

export default class Foo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: true,
      showFooter: true,
    };
  }

  render() {
    const { propertiesMap = {} } = this.props.product;
    const { template = [] } = propertiesMap;
    const logo = "https://i.imgur.com/2yr8we4.png"
    return (
      <div>
        {template.map((element, index) => {
          const { size = "", parentClass = "", backgroundColor } = element;
          const Section = styled.div`
            background-color: ${backgroundColor};
          `;
          return (
            <Section key={index} className={`${size} ${parentClass}`}>
              {ComponentPicker(element, {...this.props})}
            </Section>
          );
        })}
      </div>
    );
  }
}

import React from "react";
import request from "superagent";
import GenericProductRetailer from "./GenericProductRetailerPage";
import UDI from "./Udi";
import Car from "./Car";
import Ink from "./Ink";
import Foo from "./Foo";

export default class Consumer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetId:
        props.assetId !== null ? props.assetId : props.match.params.assetId,
      product: {},
    };
  }

  componentDidMount() {
    const { token } = this.props;
    const { assetId } = this.state;
    if (assetId) {
      let asset = {};
      let assetProduct = {};
      let assetProductType = "";
      request
        .get(`${this.props.apiUrl}assets/${assetId}`)
        .set("auth-token", this.props.token)
        .set("accept", "application/json")
        .then((res) => {
          console.log('danfoo',res)
          const { product = {} } = res.body.asset;
          const { organizationEntity } = product;
          const { properties = {}, organizationId } = organizationEntity;
          const { favicon = "" } = properties;

          // This is where we set the page title and favicon
          const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
          link.type = "image/x-icon";
          link.rel = "shortcut icon";
          link.href = favicon;
          document.title = res.body.asset.product.name;

          assetProductType = res.body.asset.product.propertiesMap.productType;
          // TODO: We need to change product over to asset. This was created back when requirements
          // were fuzzy
          assetProduct = res.body.asset.product;
          asset = res.body;

          request
            .get(`${this.props.apiUrl}organizations/${organizationId}`)
            .set("auth-token", this.props.token)
            .set("accept", "application/json")
            .then((res) => {
              this.setState({
                asset,
                organization: res.body.organization,
                product: assetProduct,
                productType: assetProductType,
              });
            });
        });
    }
  }

  // We are havin to accomodate for initial connect pages that were created.
  templatePicker() {
    const { asset, productType, product, organization } = this.state;
    switch (productType) {
      case "bicycle":
        return <GenericProductRetailer product={product} {...this.props} />;
      case "UDI":
        return <UDI product={product} {...this.props} />;
      case "car":
        return <Car product={product} {...this.props} />;
      case "ink-catridge":
        return <Ink product={product} {...this.props} />;
      default:
        return (
          <Foo
            asset={asset}
            product={product}
            organization={organization}
          />
        );
    }
  }

  render() {
    return <div>{this.templatePicker()}</div>;
  }
}
